/**
 * src/modules/admin/components/brands/Brands.tsx
 *
 * Brands tab
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/26/2023
 */

import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GQL_QUERY_ALL_BRANDS } from '../../gql/brands';
import { BrandContextInterface } from '../../models/brand-context.interface';
import { BrandInterface } from '../../models/brand-interface';
import BrandProfile from '../brand-profile/BrandProfile';
import BrandsTable from '../brand-table/BrandsTable';

export const BrandsCtx: React.Context<BrandContextInterface> = createContext({});

const Brands = () => {
  const [selectedBrand, setSelectedBrand] = useState<BrandInterface>();
  const [brandResultRows, setBrandResultRows] = React.useState<BrandInterface[]>([]);
  const [brandResultCount, setBrandResultCount] = React.useState(0);
  const [brandsLimit, setBrandsLimit] = React.useState(10);
  const [brandsOffset, setOffset] = React.useState(0);

  const { loading, error, data, refetch } = useQuery(GQL_QUERY_ALL_BRANDS, {
    variables: { limit: brandsLimit, offset: brandsOffset },
    onCompleted({ Brands: { data, total } }) {
      setBrandResultRows(data ?? []);
      setBrandResultCount(total ?? 0);
    },
    skip: true
  });

  useEffect(() => {
    refetch({ limit: brandsLimit, offset: brandsOffset });
  }, [brandsOffset, brandsLimit]);

  return (
    <BrandsCtx.Provider
      value={{
        rows: brandResultRows,
        count: brandResultCount,
        limit: brandsLimit,
        onLimit: setBrandsLimit,
        onOffset: setOffset,
        selectedBrand: selectedBrand,
        setSelectedBrand: setSelectedBrand
      }}
    >
      <Grid container item xs={12} padding={4}>
        <Grid item xs={8}>
          <BrandsTable />
        </Grid>
        <Grid item xs={4}>
          <BrandProfile />
        </Grid>
      </Grid>
    </BrandsCtx.Provider>
  );
};

export default Brands;
