/**
 * src/modules/registration/components/identity/Identity.tsx
 *
 * Gather simple details on the first user's identity.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import './Identity.scss';
import { Form } from 'react-bootstrap';

const Identity = () => (
  <div>
    <h5>About you</h5>
    <Form>
      <Form.Group className='mb-3' controlId='firstName'>
        <Form.Control type='text' placeholder='First Name' />
      </Form.Group>
      <Form.Group className='mb-3' controlId='lastName'>
        <Form.Control type='text' placeholder='Last Name' />
      </Form.Group>
    </Form>
  </div>
);

export default Identity;
