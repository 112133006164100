/**
 * src/modules/common/components/profile/Profile.tsx
 *
 * Profile component.
 *
 * @author Jake Strojny <jstrojny@materialbank.com>
 * @author Bradley Smith <bsmith@materialbank.com>
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fromPromise } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { object, string } from 'yup';
import { ReactComponent as Contacts } from '../../../common/assets/24/contacts.svg';
import { ReactComponent as Email } from '../../../common/assets/24/email.svg';
import { ReactComponent as User } from '../../../common/assets/24/user.svg';
import { GQL_UPDATE_PROFILE } from '../../gql/user';
import { ProfileInterface } from '../../models/profile.interface';
import { profileUpdate } from '../../state/actions';
import { selectProfile } from '../../state/selector';

const profileUpdateSchema = object({
  firstName: string().required().max(255),
  lastName: string().required().max(255),
  email: string().optional().email().max(255),
  phoneNumber: string().optional().max(20)
});

const Profile = () => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [updateProfile] = useMutation(GQL_UPDATE_PROFILE);

  const onSubmit: SubmitHandler<ProfileInterface> = (data) => {
    fromPromise(updateProfile({ variables: { profileInput: data } }))
      .pipe(take(1))
      .subscribe(
        (r) => {
          dispatch(profileUpdate({ profile: r?.data?.profile }));
        },
        (e) => console.log('logging error:' + e?.message ?? 'unknown error')
      );
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ProfileInterface>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(profileUpdateSchema),
    defaultValues: {
      firstName: profile.user.firstName,
      lastName: profile.user.lastName,
      email: profile.user.email,
      phoneNumber: profile.user.phoneNumber
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container padding={4} gap={7}>
        <Grid container item xs={12} gap={3}>
          <Grid item xs={12}>
            <TextField
              id='first-name-input'
              placeholder='First Name'
              variant='standard'
              error={!!errors.firstName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <User />
                  </InputAdornment>
                )
              }}
              {...register('firstName', { required: 'msg' })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='last-name-input'
              placeholder='Last Name'
              variant='standard'
              error={!!errors.lastName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <User />
                  </InputAdornment>
                )
              }}
              {...register('lastName', { required: 'msg' })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='email-input'
              placeholder='Email Address'
              variant='standard'
              error={!!errors.phoneNumber}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Email />
                  </InputAdornment>
                )
              }}
              {...register('email', { required: 'msg' })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='phone-input'
              placeholder='Phone Number'
              variant='standard'
              error={!!errors.phoneNumber}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Contacts />
                  </InputAdornment>
                )
              }}
              {...register('phoneNumber', { required: 'msg' })}
            />
          </Grid>
        </Grid>
        <Button type='submit' disabled={!isValid} variant='contained' fullWidth>
          Save
        </Button>
      </Grid>
    </form>
  );
};

export default Profile;
