/**
 * src/modules/brochure/components/main/Main.tsx
 *
 * The main brochure site, the area outside the interactive application.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import './MainBrochure.scss';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowRight } from '../../../common/assets/24/arrow-right.svg';
import BackgroundImage from '../../../common/assets/background.jpg';
import Login from '../../../common/components/login/Login';
import useScreen from '../../../common/hooks/useScreen';
import { selectIsAuthenticated } from '../../../common/state/selector';

const Brochure = ({ children }: { children?: any }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { medium } = useScreen();

  return !medium ? (
    <Box
      sx={{
        height: '716px',
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '20px',
        paddingRight: '116px',
        paddingLeft: '32px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '2rem'
        }}
      >
        <Typography variant='title1' sx={{ color: 'white' }}>
          Sourcing simplified
        </Typography>
        <Button variant='contained' sx={{ backgroundColor: '#F5F5F7', color: 'black' }}>
          Learn more
        </Button>
      </Box>
      {children && { ...children }}
      {isAuthenticated && !children && (
        <Box
          sx={{
            display: 'flex',
            marginLeft: 'auto'
          }}
        >
          <Container>
            <Link href={'/admin'} sx={{ textDecoration: 'none' }}>
              <Button variant='contained' sx={{ backgroundColor: '#F5F5F7', color: 'black' }}>
                Go to your Dashboard <ArrowRight />
              </Button>
            </Link>
          </Container>
        </Box>
      )}
      {!isAuthenticated && !children && <Login />}
    </Box>
  ) : (
    <>
      {children && { ...children }}
      {isAuthenticated && !children && (
        <Box sx={{ position: 'absolute', bottom: '4rem', left: 0, right: 0, margin: 'auto', width: 'fit-content' }}>
          <Link href={'/admin'} sx={{ textDecoration: 'none' }}>
            <Button variant='contained' sx={{ backgroundColor: '#F5F5F7', color: 'black' }}>
              Go to your Dashboard <ArrowRight />
            </Button>
          </Link>
        </Box>
      )}
      {!isAuthenticated && !children && <Login />}
    </>
  );
};

export default Brochure;
