/**
 * src/modules/common/state/effects.ts
 *
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import {ofType} from 'redux-observable';
import {mapTo, tap, withLatestFrom} from 'rxjs/operators';
import {authorizationRedirected, authorizationSuccess} from './actions';
import {selectRedirectedFrom} from './selector';
export const redirectOnAuthorization$ = (actions: any, state: any) =>
    actions.pipe(
        ofType(authorizationSuccess.type),
        withLatestFrom(state),
        tap(([, item]) => {
            let path = selectRedirectedFrom(item);
            if (path === null) {
                path = '/';
            }
            // force a location until we find a better solution.
            location.pathname = path;
        }),
        mapTo(authorizationRedirected())
    );
