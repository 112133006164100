/**
 * src/modules/common/gql/user.ts
 *
 * gql for user and profile.
 *
 * @author Jake Strojny <jstrojny@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/21/2023
 */

import { gql } from '@apollo/client';

export const GQL_UPDATE_PROFILE = gql`
  mutation updateProfile($profileInput: ProfileInput!) {
    profile(input: $profileInput) {
      user {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
`;
