/**
 * src/modules/registration/components/registration-layout/RegistrationLayout.tsx
 *
 * Layout for registering to material leads.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */

import React from 'react';
import './RegistrationLayout.scss';
import { Outlet } from 'react-router-dom';

const RegistrationLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default RegistrationLayout;
