/**
 * src/modules/admin/components/accounts-table/AccountsTable.tsx
 *
 * Accounts table
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/15/2023
 */

import React, { useContext } from 'react';
import { Grid, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactComponent as ArrowLeft } from '../../../common/assets/24/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../common/assets/24/arrow-right.svg';
import { AccountFamily, AccountInterface } from '../../models/account.interface';
import { AccountsCtx } from '../accounts/Accounts';

const AccountsTable = () => {
  const { rows, count, limit, onLimit, onOffset, setSelectedAccount } = useContext(AccountsCtx);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='accounts table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: 'grey' }}>Type</TableCell>
              <TableCell style={{ color: 'grey' }}>Brand</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((account: AccountInterface) => (
                <TableRow key={account.id} hover role='button' onClick={() => setSelectedAccount?.(account)}>
                  <TableCell style={{ color: 'grey' }}>
                    {(() => {
                      switch (account.family) {
                        case AccountFamily.BRAND:
                          return 'Brand';
                        case AccountFamily.LOCAL_DEALER:
                          return 'Local Dealer';
                      }
                    })()}
                  </TableCell>
                  <TableCell style={{ color: 'grey' }}>{account.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container item xs={12} justifyContent='flex-start' sx={{ mt: '1rem' }}>
        <Pagination
          count={count && limit ? Math.ceil(count / limit) : 0}
          renderItem={(item) => <PaginationItem slots={{ previous: ArrowLeft, next: ArrowRight }} {...item} />}
          onChange={(e, p) => {
            if (onOffset) {
              onOffset((p - 1) * (limit ?? 0));
            }
          }}
        />
      </Grid>
    </>
  );
};

export default AccountsTable;
