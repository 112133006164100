
/*
 * NewPassword.tsx
 * Compnent for submitting a new password to leads API (password and confirm password).
 * @author Anthony Plescia <aplescia@materialbank.com>
 * @copyright Material Bank (c) 2023.
 */

import React, { useState } from 'react';
import { fromPromise, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Container, FormControl, Grid, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { object, string } from 'yup';
import { GQL_NEW_PASSWORD } from '../../gql/authentication';
import { NewPasswordInterface } from '../../models/new-password-interface';

const formSchema = object({
  email: string().ensure().required(),
  resetToken: string().ensure().required(),
  newPassword: string().ensure().required()
}).required();

const NewPassword = () => {
  const [newPassword] = useMutation(GQL_NEW_PASSWORD);
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitError, setSubmitError] = useState(false);
  const onSubmit: SubmitHandler<NewPasswordInterface> = (data) => {
    fromPromise(newPassword({ variables: { input: data } })).subscribe(
      (x) => {
        setSubmitError(false);
      },
      (e) => {
        setSubmitError(true);
      }
    );
  };

  // init form.
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<NewPasswordInterface>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(formSchema)
  });

  return (
    <>
      <Container maxWidth='xs'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth={true}>
                <OutlinedInput
                  id='reset-token-input'
                  hidden={true}
                  {...register('resetToken')}
                  defaultValue={searchParams.get('resetToken')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth={true}>
                <InputLabel htmlFor='email-input' {...register('email')}>
                  {searchParams.get('email')}
                </InputLabel>
                <OutlinedInput id='email-input' hidden={true} {...register('email')} defaultValue={searchParams.get('email')} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth={true}>
                <InputLabel htmlFor='password-input'>Password</InputLabel>
                <OutlinedInput id='password-input' color='primary' {...register('newPassword')} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant='outlined' fullWidth={true}>
                <InputLabel htmlFor='confirm-password-input'>Confirm Password</InputLabel>
                <OutlinedInput id='confirm-password-input' color='primary' />
              </FormControl>
            </Grid>
            <Button variant='contained' type={'submit'}>
              Submit
            </Button>
            {submitError && <Alert severity='error'>Error occured submitting request. Please contact your administrator.</Alert>}
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default NewPassword;
