/**
 * src/modules/admin/components/admin-layout/AdminLayout.tsx
 *
 * Main layout for the admin section of the site.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */

import React, { useState } from 'react';
import { Box, Drawer, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import { NavLink, Outlet } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import { ReactComponent as Menu } from '../../../common/assets/32/menu.svg';
import useScreen from '../../../common/hooks/useScreen';

const AdminLayout = () => {
  const [open, setOpen] = useState(false);
  const { medium } = useScreen();

  return !medium ? (
    <>
      <Grid container item xs={12} gap={5} paddingY={1.5} sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <NavLink
          to='/admin/brands'
          className={({ isActive }) => {
            console.log(isActive);
            return isActive ? styles.adminLayout__link_active : styles.adminLayout__link;
          }}
        >
          <Typography>Brands</Typography>
        </NavLink>
        <NavLink
          to='/admin/leads'
          className={({ isActive }) => {
            console.log(isActive);
            return isActive ? styles.adminLayout__link_active : styles.adminLayout__link;
          }}
        >
          <Typography>Leads</Typography>
        </NavLink>
        <NavLink
          to='/admin/accounts'
          className={({ isActive }) => {
            console.log(isActive);
            return isActive ? styles.adminLayout__link_active : styles.adminLayout__link;
          }}
        >
          <Typography>Accounts</Typography>
        </NavLink>
        <NavLink
          to='/admin/reports'
          className={({ isActive }) => {
            console.log(isActive);
            return isActive ? styles.adminLayout__link_active : styles.adminLayout__link;
          }}
        >
          <Typography>Reports</Typography>
        </NavLink>
      </Grid>
      <Outlet />
    </>
  ) : (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <Menu />
      </IconButton>
      <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
        <Box role='presentation' sx={{ width: '250px' }} onClick={() => setOpen(false)} onKeyDown={() => setOpen(false)}>
          <List>
            <ListItem key='brands'>
              <NavLink
                to='/admin/brands'
                className={({ isActive }) => {
                  console.log(isActive);
                  return isActive ? styles.adminLayout__link_active_mobile : styles.adminLayout__link_mobile;
                }}
              >
                <Typography>Brands</Typography>
              </NavLink>
            </ListItem>
            <ListItem key='leads'>
              <NavLink
                to='/admin/leads'
                className={({ isActive }) => {
                  console.log(isActive);
                  return isActive ? styles.adminLayout__link_active_mobile : styles.adminLayout__link_mobile;
                }}
              >
                <Typography>Leads</Typography>
              </NavLink>
            </ListItem>
            <ListItem key='accounts'>
              <NavLink
                to='/admin/accounts'
                className={({ isActive }) => {
                  console.log(isActive);
                  return isActive ? styles.adminLayout__link_active_mobile : styles.adminLayout__link_mobile;
                }}
              >
                <Typography>Accounts</Typography>
              </NavLink>
            </ListItem>
            <ListItem key='reports'>
              <NavLink
                to='/admin/reports'
                className={({ isActive }) => {
                  console.log(isActive);
                  return isActive ? styles.adminLayout__link_active_mobile : styles.adminLayout__link_mobile;
                }}
              >
                <Typography>Reports</Typography>
              </NavLink>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Outlet />
    </>
  );
};

export default AdminLayout;
