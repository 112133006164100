/**
 * src/modules/brochure/components/brochure-layout/BrochureLayout.tsx
 *
 * Main layout for the public section of the site.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */

import React from 'react';
import './BrochureLayout.scss';
import { Outlet } from 'react-router-dom';

const BrochureLayout = () => (
  <>
    <Outlet />
  </>
);

export default BrochureLayout;
