/**
 * src/modules/common/state/reducers.ts
 *
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import { on, reducer } from 'ts-action';
import { authorizationRedirected, authorizationSuccess, loggedOut, profileUpdate, unauthorizedRedirect } from './actions';
import { CommonStateInterface } from '../models/common-state.interface';

const initialState: CommonStateInterface = {
  brochure: null,
  profile: null,
  redirectedFrom: null,
  registration: null
};
export const commonReducer = reducer<CommonStateInterface>(
  initialState,
  on(unauthorizedRedirect, (state, { payload }) => ({ ...state, redirectedFrom: payload.origin })),
  on(authorizationSuccess, (state, { payload }) => ({ ...state, profile: payload.profile })),
  on(authorizationRedirected, (state) => ({ ...state, redirectedFrom: null })),
  on(profileUpdate, (state, { payload }) => ({ ...state, profile: payload.profile })),
  on(loggedOut, () => initialState)
);
