/**
 * src/modules/registration/components/consignee/Consignee.tsx
 *
 * Gather organization details.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import './Consingee.scss';
import { Form } from 'react-bootstrap';

const Consignee = () => (
  <div>
    <h5>About your Organization</h5>
    <Form>
      <Form.Group className='mb-3' controlId='consigneeName'>
        <Form.Control type='text' placeholder='Name' />
      </Form.Group>
    </Form>
  </div>
);

export default Consignee;
