/**
 * src/modules/admin/AdminRoutes.tsx
 *
 * Semantic routing for this module.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Accounts from './components/accounts/Accounts';
import AdminLayout from './components/admin-layout/AdminLayout';
import Brands from './components/brands/Brands';
import Dashboard from './components/dashboard/Dashboard';
import PrivateRoute from '../common/components/private-route/PrivateRoute';

export const AdminRoutes = () => {
  return (
    <>
      <PrivateRoute roles={['ROLE_USER']}>
        <Routes>
          <Route element={<AdminLayout />}>
            <Route path={'/*'} element={<Navigate to='/admin/leads' />} />
            <Route path='/brands' element={<Brands />} />
            <Route path='/leads' element={<Dashboard />} />
            <Route path='/accounts' element={<Accounts />} />
            <Route path='/reports' element={<Dashboard />} />
          </Route>
        </Routes>
      </PrivateRoute>
    </>
  );
};
