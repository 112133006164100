/*
 * AccountRoutes.tsx
 * Routes for /account/* pages.
 * @author Anthony Plescia <aplescia@materialbank.com>
 * @copyright Material Bank (c) 2023.
 */

import {Route, Routes} from 'react-router-dom';
import React from 'react';
import NewPassword from '../../components/reset-password/NewPassword';

export const AccountRoutes = () => {
    return (
        <>
            <Routes>
                    <Route path={'/reset-password'} element={<NewPassword />}></Route>
            </Routes>
        </>
    );
};