import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true;
    title3: true;
    headline1: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  title1: React.CSSProperties;
  title3: React.CSSProperties;
  headline1: React.CSSProperties;
}

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '3rem',
          borderRadius: '8px',
          textTransform: 'none',
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
          '&.Mui-disabled': {
            background: '#F5F5F7',
            color: '#86868B'
          }
        },
        contained: {
          background: '#000000',
          color: '#FFFFFF'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#F2F2F2',
          opacity: 1
        }
      }
    }
  },
  palette: {
    // type: 'light',
    primary: {
      main: '#4D4E58',
      light: '#3399CC',
      dark: '#237EAB'
    },
    secondary: {
      main: '#F5F5F7',
      light: '#E4E4E4',
      dark: '#fff'
    },
    error: {
      main: '#ED6DB8',
      contrastText: '#fff'
    },
    warning: {
      main: '#FFD33C'
    },
    success: {
      main: '#7DC64E',
      light: '#44D62C',
      contrastText: '#fff'
    },
    info: {
      main: '#3399CC'
    }
  },
  typography: {
    fontFamily: 'Graphik, Arial',
    title1: {
      fontWeight: 700,
      fontSize: '3.75rem',
      lineHeight: '4.5rem'
    },
    title3: {
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '2rem'
    },
    headline1: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '2rem'
    },
    body: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem'
    }
  } as ExtendedTypographyOptions
});
