/**
 * src/modules/common/components/header/Drawer.tsx
 *
 * Drawer component.
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/06/2023
 */

import React, { createContext, useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Avatar, Box, Button, Divider, SwipeableDrawer, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fromPromise } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { ReactComponent as Cross } from '../../../common/assets/32/cross.svg';
import { GQL_LOGOUT } from '../../gql/authentication';
import { DrawerContextInterface } from '../../models/drawer-context.interface';
import { loggedOut } from '../../state/actions';
import { selectProfile } from '../../state/selector';
import Profile from '../profile/Profile';

export const DrawerCtx: React.Context<DrawerContextInterface> = createContext({});

const Drawer = () => {
  const { open, setOpen } = useContext(DrawerCtx);
  const profile = useSelector(selectProfile);

  const dispatch = useDispatch();
  const [logout] = useMutation(GQL_LOGOUT);
  const onClick = useCallback(() => {
    fromPromise(logout())
      .pipe(take(1))
      .subscribe(
        () => dispatch(loggedOut()),
        () => dispatch(loggedOut()),
        () => dispatch(loggedOut())
      );
  }, []);

  const onSetOpen = (state: boolean) => setOpen && setOpen(state);

  return (
    <SwipeableDrawer
      anchor='right'
      open={open}
      onOpen={() => onSetOpen(true)}
      onClose={() => onSetOpen(false)}
      PaperProps={{
        sx: {
          marginTop: { xs: '52px', sm: 0 },
          borderRadius: { xs: '20px 20px 0px 0px', sm: 0 },
          height: { xs: 'calc(100% - 52px)', sm: '100%' }
        }
      }}
    >
      <Box
        sx={{ width: { xs: '100%', sm: '424px' } }}
        role='presentation'
        onKeyDown={(e) => {
          if (e.keyCode !== 27) {
            return;
          }
          onSetOpen(false);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 3.5,
            paddingX: 4
          }}
        >
          <Box>
            <Avatar
              alt={profile.user.firstName + profile.user.lastName}
              sx={{
                width: 96,
                height: 96,
                borderRadius: 16,
                backgroundColor: '#D2D2FF',
                color: 'primary.main',
                marginBottom: 2.5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography variant='headline1'>
                {profile.user.firstName[0]}
                {profile.user.lastName[0]}
              </Typography>
            </Avatar>
          </Box>
        </Box>
        <Button variant='text' sx={{ position: 'absolute', top: 5, right: 5 }} onClick={() => onSetOpen(false)}>
          <Cross />
        </Button>
        <Profile />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            paddingX: 2.5,
            textAlign: 'center'
          }}
        >
          <Divider />
          <Button variant='text' sx={{ marginX: 'auto', marginY: 2 }} onClick={onClick}>
            Log Out
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default Drawer;
