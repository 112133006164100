/**
 * src/modules/registration/pages/registration/SignupWizard.tsx
 *
 * Signup wizard
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import './SignupWizard.scss';
import Consignee from '../../components/consignee/Consignee';
import Credentials from '../../components/credentials/Credentials';
import Identity from '../../components/identity/Identity';

const SignupWizard = () => {
  return (
    <div>
      <Consignee />
      <Identity />
      <Credentials />
    </div>
  );
};

export default SignupWizard;
