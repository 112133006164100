import { useMediaQuery, useTheme } from '@mui/material';

const useScreen = () => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const medium = useMediaQuery(theme.breakpoints.down('md'));
  const large = useMediaQuery(theme.breakpoints.down('lg'));

  return { small, medium, large };
};

export default useScreen;
