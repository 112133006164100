/**
 * src/modules/admin/components/dashboard/Dashboard.tsx
 *
 * A dashboard, for the admin. 🙃
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React, { useEffect } from 'react';
import './Dashboard.scss';
import { useQuery } from '@apollo/client';
import { LeadInterface } from '../../../common/models/lead.interface';
import { GQL_QUERY_ALL_LEADS } from '../../gql/leads';
import LeadsTable, { LeadTableCtx } from '../leads-table/LeadsTable';

const Dashboard = () => {
  const [leadResultRows, setLeadResultRows] = React.useState<LeadInterface[]>([]);
  const [leadResultCount, setLeadResultCount] = React.useState(0);
  const [leadsLimit, setLeadsLimit] = React.useState(5);
  const [leadsOffset, setOffset] = React.useState(0);

  const { loading, error, data, refetch } = useQuery(GQL_QUERY_ALL_LEADS, {
    variables: { limit: leadsLimit, offset: leadsOffset },
    onCompleted({ Leads: { data, total } }) {
      setLeadResultRows(data ?? []);
      setLeadResultCount(total ?? 0);
    },
    skip: true
  });

  useEffect(() => {
    refetch({ limit: leadsLimit, offset: leadsOffset });
  }, [leadsOffset, leadsLimit]);

  return (
    <>
      <LeadTableCtx.Provider
        value={{
          rows: leadResultRows,
          count: leadResultCount,
          limit: leadsLimit,
          onLimit: setLeadsLimit,
          onOffset: setOffset
        }}
      >
        <LeadsTable></LeadsTable>
      </LeadTableCtx.Provider>
    </>
  );
};

export default Dashboard;
