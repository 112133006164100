/**
 * src/modules/brochure/BrochureRoutes.tsx
 *
 * Semantic routing for this module.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BrochureLayout from './components/brochure-layout/BrochureLayout';
import Brochure from './components/main-brochure/MainBrochure';

export const BrochureRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<BrochureLayout />}>
          <Route path={'/*'} element={<Brochure />}></Route>
        </Route>
      </Routes>
    </>
  );
};
