/**
 * src/modules/registration/components/credentials/Credentials.tsx
 *
 * Login/Password for the user.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import './Credentials.scss';
import { Form } from 'react-bootstrap';

const Credentials = () => (
  <div>
    <h5>Login Details</h5>
    <Form>
      <Form.Group className='mb-3' controlId='emailAddress'>
        <Form.Control type='email' placeholder='Email Address' />
      </Form.Group>
      <Form.Group className='mb-3' controlId='password'>
        <Form.Control type='text' placeholder='Password' />
      </Form.Group>
    </Form>
  </div>
);

export default Credentials;
