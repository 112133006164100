/**
 * src/modules/common/state/selector.ts
 *
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import { createSelector } from '@reduxjs/toolkit';
import { store } from './store';

export const selectCommon = createSelector(store.getState, (state) => state.common);
export const selectProfile = createSelector(selectCommon, (state) => state.profile);
export const selectRedirectedFrom = createSelector(selectCommon, (state) => state.redirectedFrom);
export const selectIsAuthenticated = createSelector(selectProfile, (profile) => !!profile);
