/**
 * src/modules/common/components/login/Login.tsx
 *
 * Login component.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Container, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { fromPromise } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { object, string } from 'yup';
import { ReactComponent as ArrowRight } from '../../assets/24/arrow-right.svg';
import { ReactComponent as Email } from '../../assets/24/email.svg';
import { ReactComponent as Hide } from '../../assets/24/hide.svg';
import { ReactComponent as Password } from '../../assets/24/password.svg';
import { ReactComponent as Show } from '../../assets/24/show.svg';
import { GQL_LOGIN } from '../../gql/authentication';
import { LoginInputsInterface } from '../../models/login-inputs.interface';
import { authorizationSuccess } from '../../state/actions';
import ResetPassword from '../reset-password/ResetPassword';

const formSchema = object({
  email: string().ensure().required().email(),
  password: string().ensure().required().min(10)
}).required();

const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginResult, setLoginResult] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);

  const [login, { loading }] = useMutation(GQL_LOGIN);
  const onSubmit: SubmitHandler<LoginInputsInterface> = (data) => {
    setLoginResult('');
    fromPromise(login({ variables: { loginInput: data } }))
      .pipe(take(1))
      .subscribe(
        (x) => {
          dispatch(authorizationSuccess({ profile: x.data?.login }));
        },
        (e) => setLoginResult(e?.message ?? 'unknown error')
      );
  };

  // init form.
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<LoginInputsInterface>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(formSchema)
  });

  return (
    <Container maxWidth='xs' sx={{ backgroundColor: 'white', borderRadius: '20px' }}>
      {!passwordReset && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container paddingX={{ xs: 0, md: 4 }} paddingY={4} gap={7}>
            <Grid container item xs={12} gap={3}>
              <Grid item xs={12} paddingY={0.5} textAlign='center'>
                <Typography variant='headline1'>Welcome back</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='email-input'
                  placeholder='Email'
                  variant='standard'
                  error={!!errors.email}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Email />
                      </InputAdornment>
                    )
                  }}
                  {...register('email', { required: 'msg' })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='password-input'
                  placeholder='Password'
                  variant='standard'
                  error={!!errors.password}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Password />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(event) => event.preventDefault()}
                          edge='end'
                        >
                          {showPassword ? <Hide /> : <Show />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  {...register('password', { required: true })}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={3}>
              <Grid container item xs={12} gap={1}>
                <Grid item xs={12}>
                  <Button variant='text' fullWidth onClick={() => setPasswordReset(true)}>
                    Forgot your password?
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {!loading && (
                    <Button type='submit' disabled={!isValid} variant='contained' fullWidth>
                      Sign In
                    </Button>
                  )}
                  {loading && <Spinner animation='border' role='status'></Spinner>}
                </Grid>
              </Grid>
              <Grid xs={12} item>
                <Divider />
              </Grid>
              <Grid container item xs={12} gap={1} direction='row' justifyContent='center'>
                <Typography color='grey' alignSelf='center'>
                  Are you new here?
                </Typography>
                <Button variant='text'>
                  <Typography>Sign Up</Typography>
                  <ArrowRight />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {loginResult && <Alert severity='error'>{loginResult}</Alert>}
      {passwordReset && <ResetPassword />}
    </Container>
  );
};

export default Login;
