/**
 * src/modules/admin/components/account-profile/AccountProfile.tsx
 *
 * Account profile
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/16/2023
 */

import React, { useContext } from 'react';
import { Box, Divider, Grid, Switch, Typography } from '@mui/material';
import { ReactComponent as Clock } from '../../../common/assets/24/clock.svg';
import { ReactComponent as Contacts } from '../../../common/assets/24/contacts.svg';
import { ReactComponent as Delivery } from '../../../common/assets/24/delivery.svg';
import { ReactComponent as Download } from '../../../common/assets/24/download.svg';
import { ReactComponent as Email } from '../../../common/assets/24/email.svg';
import { ReactComponent as Link } from '../../../common/assets/24/link.svg';
import { ReactComponent as Pin } from '../../../common/assets/24/pin.svg';
import { ReactComponent as User } from '../../../common/assets/24/user.svg';
import { DeliveryMethod } from '../../models/account.interface';
import { AccountsCtx } from '../accounts/Accounts';

const AccountProfile = () => {
  const { rows, selectedAccount } = useContext(AccountsCtx);

  if (!rows) {
    return (
      <Box padding={4} textAlign='center'>
        <Typography variant='headline1'>No account registered.</Typography>
      </Box>
    );
  }

  if (!selectedAccount) {
    return (
      <Box padding={4} textAlign='center'>
        <Typography variant='headline1'>No account is selected</Typography>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Contacts style={{ marginRight: 2 }} />
          Phone Number
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{selectedAccount.phoneNumber}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <User style={{ marginRight: 2 }} />
          Status
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>
            <Switch
              checked={selectedAccount.enabled}
              onChange={() => {
                /**noop**/
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Pin style={{ marginRight: 2 }} />
          Address
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>
          {[selectedAccount.street, selectedAccount.city, selectedAccount.state, selectedAccount.postalCode].join(', ')}
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Clock style={{ marginRight: 2 }} />
          Lead delay in hours
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>
          {selectedAccount.deliveryDelay > 0 && selectedAccount.deliveryDelay / 3600}
          {selectedAccount.deliveryDelay == 0 && <>No delay</>}
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Delivery style={{ marginRight: 2 }} />
          Delivery method
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>
          {(() => {
            switch (selectedAccount.deliveryMethod) {
              case DeliveryMethod.SFTP:
                return (
                  <>
                    <Download /> SFTP
                  </>
                );
              case DeliveryMethod.EMAIL:
                return (
                  <>
                    <Email /> Email
                  </>
                );
              case DeliveryMethod.WEB_APP:
                return (
                  <>
                    <Link /> Web App
                  </>
                );
            }
          })()}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountProfile;
