/**
 * src/modules/admin/components/leads-table
 *
 * Display leads system-wide.
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/30/2023
 */

import React, { createContext, useContext } from 'react';
import { Grid, Pagination, PaginationItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactComponent as ArrowLeft } from '../../../common/assets/24/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../common/assets/24/arrow-right.svg';
import { LeadInterface } from '../../../common/models/lead.interface';
import { PaginatedTableContextInterface } from '../../models/paginated-table-context.interface';

export const LeadTableCtx: React.Context<PaginatedTableContextInterface<LeadInterface>> = createContext({});

const LeadsTable = () => {
  const { rows, count, limit, onLimit, onOffset } = useContext(LeadTableCtx);

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label='leads table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'grey' }}>Order Date</TableCell>
                <TableCell style={{ color: 'grey' }}>First Name</TableCell>
                <TableCell style={{ color: 'grey' }}>Last Name</TableCell>
                <TableCell style={{ color: 'grey' }}>Email</TableCell>
                <TableCell style={{ color: 'grey' }}>Details</TableCell>
                <TableCell style={{ color: 'grey' }}>Quantity</TableCell>
                <TableCell style={{ color: 'grey' }}>Phone</TableCell>
                <TableCell style={{ color: 'grey' }}>Address</TableCell>
                <TableCell style={{ color: 'grey' }}>Manufacturer Sku</TableCell>
                <TableCell style={{ color: 'grey' }}>Name</TableCell>
                <TableCell style={{ color: 'grey' }}>Company</TableCell>
                <TableCell style={{ color: 'grey' }}>Company Practice</TableCell>
                <TableCell style={{ color: 'grey' }}>Title</TableCell>
                <TableCell style={{ color: 'grey' }}>Shipped</TableCell>
                <TableCell style={{ color: 'grey' }}>SKU</TableCell>
                <TableCell style={{ color: 'grey' }}>MBID</TableCell>
                <TableCell style={{ color: 'grey' }}>Color</TableCell>
                <TableCell style={{ color: 'grey' }}>Serial SKU</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((lead: LeadInterface) => (
                  <TableRow key={lead.id}>
                    <TableCell style={{ color: 'grey' }}>{lead.orderDate}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.firstName}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.lastName}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.email}</TableCell>
                    <TableCell style={{ color: 'grey' }}>
                      {lead.projectName} / {lead.projectType}
                    </TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.quantity}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.phone}</TableCell>
                    <TableCell style={{ color: 'grey' }}>
                      {lead.address}, {lead.state}, {lead.zipCode}
                    </TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.color}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.state}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.zipCode}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.manufacturerSku}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.name}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.company}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.companyPractice}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.title}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.mbShipped}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.sku}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.mbid}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.color}</TableCell>
                    <TableCell style={{ color: 'grey' }}>{lead.serialSKU}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12} justifyContent='flex-end' sx={{ mt: '1rem' }}>
        <Pagination
          count={count && limit ? Math.ceil(count / limit) : 0}
          renderItem={(item) => <PaginationItem slots={{ previous: ArrowLeft, next: ArrowRight }} {...item} />}
          onChange={(e, p) => {
            if (onOffset) {
              onOffset((p - 1) * (limit ?? 0));
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LeadsTable;
