/**
 * src/modules/common/services/apollo.ts
 *
 * configure graphql
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */
import { ApolloClient, InMemoryCache } from '@apollo/client';

export const makeApolloClient = () => {


  const endPoint = process.env.REACT_APP_API_ENDPOINT ?? '';

  return new ApolloClient({
    uri: `${endPoint}/query`,
    cache: new InMemoryCache(),
    credentials: 'include'
  });

};
