/**
 * /src/modules/common/components/private-route/PrivateRoute.tsx
 *
 * Header component.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/31/2023
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { unauthorizedRedirect } from '../../state/actions';
import { selectIsAuthenticated } from '../../state/selector';

const PrivateRoute = ({ children, roles }: { children: JSX.Element; roles?: string[] }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  // redirect if unauthorized.
  if (!isAuthenticated) {
    if (location.pathname !== '/login') {
      useDispatch()(unauthorizedRedirect({ origin: location.pathname }));
    }

    return <Navigate to='/login' />;
  }

  // const profile = useSelector(selectProfile);

  // if (!profile?.user?.roles?.all(roles)) {
  //   return <Typography>Access Denied!</Typography>;
  // }

  return children;
};

export default PrivateRoute;
