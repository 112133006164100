/**
 * src/modules/common/state/actions.ts
 *
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import { action, payload } from 'ts-action';
import { ProfileInterface } from '../models/profile.interface';

export const unauthorizedRedirect = action('[Authentication] Redirect For Authentication', payload<{ origin: string }>());
export const authorizationSuccess = action('[Authentication] Authorization Success', payload<{ profile: ProfileInterface }>());
export const authorizationRedirected = action('[Authentication] Redirect Back');
export const loggedOut = action('[Authentication] Logged Out');
export const profileUpdate = action('[User] Profile Updated', payload<{ profile: ProfileInterface }>());
