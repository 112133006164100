/**
 * src/modules/registration/RegistrationRoutes.tsx
 *
 * Semantic routing for this module.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RegistrationLayout from './components/registration-layout/RegistrationLayout';
import SignupWizard from './pages/registration/SignupWizard';

export const RegistrationRoutes = () => {
  return (
    <Routes>
      <Route element={<RegistrationLayout />}>
        <Route path={'/'} element={<SignupWizard />}></Route>
      </Route>
    </Routes>
  );
};
