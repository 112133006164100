/**
 * src/modules/common/components/reset-password/ResetPassword.tsx
 *
 * ResetPassword component.
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/23/2023
 */

import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, Container, FormControl, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { PasswordResetRequestInterface } from '../../models/password-reset-request.interface';
import {  useMutation } from '@apollo/client';
import { GQL_RESET_PASSWORD } from '../../gql/authentication';
import {take} from 'rxjs/operators';
import {fromPromise} from 'rxjs/internal-compatibility';

const formSchema = object({
  email: string().ensure().required().email()
}).required();

const ResetPassword = () => {
  const [reset] = useMutation(GQL_RESET_PASSWORD);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const onSubmit: SubmitHandler<PasswordResetRequestInterface> = (data) => {
    fromPromise(reset({ variables: { input: data } }))
        .pipe(take(1))
        .subscribe(
      (x) => {
        setSuccess(true);
      },
      (e) => {
        setError(e.message);
      }
    );
  };

  // init form.
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<PasswordResetRequestInterface>({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(formSchema)
  });

  return (
    <>
      <Container maxWidth='xs'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Reset your password</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl error={!!errors.email} variant='outlined' fullWidth={true}>
                <InputLabel htmlFor='email-input'>Email</InputLabel>
                <OutlinedInput id='email-input' color='primary' {...register('email', { required: 'msg' })} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Enter the email address linked to your DesignShop account, and we will send you a reset link.
              </Typography>
              {success && <Alert severity='success'>Email Sent</Alert>}
              {error != '' && <Alert severity='error'>{error}</Alert>}
            </Grid>
            <Grid item xs={12}>
              {!loading && (
                <Box display='flex' justifyContent='flex-end'>
                  <Button variant='contained' type={'submit'}>
                    Get reset link
                  </Button>
                </Box>
              )}
              {loading && <Spinner animation='border' role='status'></Spinner>}
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default ResetPassword;
