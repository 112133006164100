/**
 * src/modules/common/state/common-store.ts
 *
 * This is the base store for the application.
 *
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import {combineReducers} from '@reduxjs/toolkit';
import {createStore, IModuleStore} from 'redux-dynamic-modules';
import {getObservableExtension, IEpicModule} from 'redux-dynamic-modules-observable';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {redirectOnAuthorization$} from './effects';
import {commonReducer} from './reducers';

export const store: IModuleStore<any> = createStore(
  {
    extensions: [getObservableExtension()],
    advancedCombineReducers: reducers =>
        persistReducer(
            {
              key: 'root',
              storage,
              whitelist: ['common'],
            },
            combineReducers(reducers as any)
        ) as any,
  },
  {
    id: 'common',
    reducerMap: {
      common: commonReducer,
    },
      epics: [redirectOnAuthorization$],
      initialActions: [],
      finalActions: []
  } as IEpicModule<any>
);

export const persistor = persistStore(store);
