/**
 * src/modules/common/gql/authentication.ts
 *
 * gql for authentication.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */
import { gql } from '@apollo/client';

export const GQL_QUERY_ALL_LEADS = gql`
  query Leads($offset: Int!, $limit: Int!) {
    Leads(offset: $offset, limit: $limit) {
      data {
        id
        orderDate
        firstName
        lastName
        email
        projectName
        projectType
        quantity
        phone
        address
        city
        state
        zipCode
        manufacturerSku
        name
        company
        companyPractice
        title
        mbShipped
        sku
        mbid
        color
        serialSKU
      }
      total
    }
  }
`;
