/**
 * src/modules/admin/models/accounts.interface.ts
 *
 * Accounts interface
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/16/2023
 */

export enum AccountFamily {
  BRAND = 'BRAND',
  LOCAL_DEALER = 'LOCAL_DEALER'
}

export interface AccountInterface {
  id: string | number;
  enabled: boolean;
  family: AccountFamily;
  name: string;
  description: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
  tradeCurrency: string;
  phoneNumber: string;
  faxNumber: string;
  website: string;
  created: number;
  deliveryDelay: number;
  deliveryMethod: DeliveryMethod;
}

export enum DeliveryMethod {
  SFTP = 'SFTP',
  EMAIL = 'E_MAIL',
  WEB_APP = 'WEB'
}
