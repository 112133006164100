/**
 * src/modules/common/gql/authentication.ts
 *
 * gql for authentication.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/09/2023
 */
import { gql } from '@apollo/client';

// attempt to login.
export const GQL_LOGIN = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;

export const GQL_LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const GQL_RESET_PASSWORD = gql`
  mutation passwordResetInit($input: PasswordResetRequestInput) {
    passwordResetInit(input: $input) {
      success
      resetToken
      error
    }
  }
`;

export const GQL_NEW_PASSWORD = gql`
  mutation passwordReset($input: NewPasswordInput) {
    passwordReset(input: $input) {
      success
      error
    }
  }
`;
