/**
 * src/modules/admin/components/accounts/Accounts.tsx
 *
 * Accounts tab
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/16/2023
 */

import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { GQL_QUERY_ALL_ACCOUNTS } from '../../gql/accounts';
import { AccountsContextInterface } from '../../models/account-context.interface';
import { AccountInterface } from '../../models/account.interface';
import AccountProfile from '../account-profile/AccountProfile';
import AccountsTable from '../accounts-table/AccountsTable';

export const AccountsCtx: React.Context<AccountsContextInterface> = createContext({});

const Accounts = () => {
  const [selectedAccount, setSelectedAccount] = useState<AccountInterface>();
  const [accountResultRows, setAccountResultRows] = React.useState<AccountInterface[]>([]);
  const [accountResultCount, setAccountResultCount] = React.useState(0);
  const [accountsLimit, setAccountsLimit] = React.useState(10);
  const [accountsOffset, setOffset] = React.useState(0);

  const { loading, error, data, refetch } = useQuery(GQL_QUERY_ALL_ACCOUNTS, {
    variables: { limit: accountsLimit, offset: accountsOffset },
    onCompleted({ Consignees: { data, total } }) {
      setAccountResultRows(data ?? []);
      setAccountResultCount(total ?? 0);
    },
    skip: true
  });

  useEffect(() => {
    refetch({ limit: accountsLimit, offset: accountsOffset });
  }, [accountsOffset, accountsLimit]);

  return (
    <AccountsCtx.Provider
      value={{
        rows: accountResultRows,
        count: accountResultCount,
        limit: accountsLimit,
        onLimit: setAccountsLimit,
        onOffset: setOffset,
        selectedAccount: selectedAccount,
        setSelectedAccount: setSelectedAccount
      }}
    >
      <Grid container item xs={12} padding={4}>
        <Grid item xs={8}>
          <AccountsTable />
        </Grid>
        <Grid item xs={4}>
          <AccountProfile />
        </Grid>
      </Grid>
    </AccountsCtx.Provider>
  );
};

export default Accounts;
