/** src/modules/common/components/app/App.tsx
 *
 * Bootstraps the lead application, provides initial routing, etc.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/06/2023
 */

import React from 'react';
import { ThemeProvider } from '@emotion/react';
import loadable from '@loadable/component';
import { Container } from '@mui/material';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.scss';
import { AdminRoutes } from '../../../admin/AdminRoutes';
import { theme } from '../../../admin/theme';
import { BrochureRoutes } from '../../../brochure/BrochureRoutes';
import MainBrochure from '../../../brochure/components/main-brochure/MainBrochure';
import { RegistrationRoutes } from '../../../registration/RegistrationRoutes';
import {AccountRoutes} from '../../pages/account/AccountRoutes';
import Header from '../header/Header';

const NotFound = loadable(() => import('../not-found/NotFound'));
const Login = loadable(() => import('../login/Login'));

const App: any = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <Container maxWidth='xl'>
                <Header />
                <Outlet />
              </Container>
            }
          >
            <Route path={'/'} element={<BrochureRoutes />} />
            <Route
              path={'/login'}
              element={
                <MainBrochure>
                  <Login />
                </MainBrochure>
              }
            />
            <Route path='/register/*' element={<RegistrationRoutes />} />
            <Route path='/admin/*' element={<AdminRoutes />} />
            <Route path='/account/*' element={<AccountRoutes />}/>
          </Route>
          <Route path={'/*'} element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
