/**
 * src/modules/common/components/header/Header.tsx
 *
 * Header component.
 *
 * @author Bradley Smith <bsmith@materialbank.com>
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 01/26/2023
 */

import React, { useState } from 'react';
import { Box, Button, Grid, InputAdornment, Link, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Drawer, { DrawerCtx } from './Drawer';
import { ReactComponent as ArrowDown } from '../../../common/assets/24/arrow-down.svg';
import { ReactComponent as Search } from '../../../common/assets/24/search.svg';
import { ReactComponent as Cross } from '../../../common/assets/32/cross.svg';
import { ReactComponent as Menu } from '../../../common/assets/32/menu.svg';
import { ReactComponent as User } from '../../../common/assets/32/user.svg';
import { ReactComponent as MBLogo } from '../../../common/assets/logo_m.svg';
import useScreen from '../../hooks/useScreen';
import { selectIsAuthenticated, selectProfile } from '../../state/selector';

const Header = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const profile = useSelector(selectProfile);
  const [openHeaderDrawer, setOpenHeaderDrawer] = useState(false);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const { medium } = useScreen();

  const renderAuthenticated = (
    <>
      <Grid container item xs='auto' gap={1} onClick={() => setOpenProfileDrawer(true)} sx={{ alignItems: 'center', cursor: 'pointer' }}>
        <User />
        {!medium && (
          <Box sx={{ justifyContent: 'center' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography>Profile</Typography>
              <ArrowDown />
            </Box>
            <Typography variant='subtitle2' color='#86868B'>
              Hi, {profile?.user.firstName}
            </Typography>
          </Box>
        )}
      </Grid>
      <DrawerCtx.Provider value={{ open: openProfileDrawer, setOpen: setOpenProfileDrawer }}>
        <Drawer />
      </DrawerCtx.Provider>
    </>
  );

  const renderUnauthenticated = !medium ? (
    <Grid container item xs='auto' gap={3}>
      <Grid item>
        <Link href={'/login'} sx={{ textDecoration: 'none' }}>
          <Button variant='contained' fullWidth>
            Sign In
          </Button>
        </Link>
      </Grid>
      <Grid item>
        <Button fullWidth>Sign Up</Button>
      </Grid>
      <Grid item>
        <Button fullWidth>Cart</Button>
      </Grid>
    </Grid>
  ) : (
    <>
      <Menu style={{ cursor: 'pointer' }} onClick={() => setOpenHeaderDrawer(true)} />
      <SwipeableDrawer
        anchor='right'
        open={openHeaderDrawer}
        onOpen={() => setOpenHeaderDrawer(true)}
        onClose={() => setOpenHeaderDrawer(false)}
        PaperProps={{
          sx: {
            width: '100%',
            justifyContent: 'center'
          }
        }}
      >
        <Box
          sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1rem', padding: '2rem' }}
          role='presentation'
          onKeyDown={(e) => {
            if (e.keyCode !== 27) {
              return;
            }
            setOpenHeaderDrawer(false);
          }}
        >
          <Button variant='text' sx={{ position: 'absolute', top: 5, right: 5 }} onClick={() => setOpenHeaderDrawer(false)}>
            <Cross />
          </Button>
          <Link href={'/login'} sx={{ textDecoration: 'none' }}>
            <Button variant='contained' fullWidth>
              Sign In
            </Button>
          </Link>
          <Button fullWidth>Sign Up</Button>
          <Button fullWidth>Cart</Button>
        </Box>
      </SwipeableDrawer>
    </>
  );

  const renderSearchBar = (
    <TextField
      id='outlined-adornment-weight'
      aria-describedby='outlined-weight-helper-text'
      aria-label='weight'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search style={{ stroke: '#86868B', cursor: 'pointer', width: '1.25rem' }} />
          </InputAdornment>
        ),
        disableUnderline: true,
        sx: { backgroundColor: '#F5F5F7', borderRadius: 3, height: '3rem', padding: 1.5 }
      }}
      variant='standard'
      placeholder='Find ...'
      fullWidth={true}
    />
  );

  return (
    <Box
      marginX={{ xs: 0, md: 4 }}
      marginBottom={{ xs: 0, md: 4 }}
      sx={{ borderBottom: '1px #F2F2F2 solid', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid container item xs={12} gap={2} paddingY={1.5}>
        <MBLogo style={{ width: '3rem', height: '3rem' }} />
        <Grid item xs>
          {!medium && <Box sx={{ paddingLeft: 3, paddingRight: 3 }}> {renderSearchBar}</Box>}
        </Grid>
        {isAuthenticated ? renderAuthenticated : renderUnauthenticated}
      </Grid>
      <Grid item xs paddingBottom={1.5}>
        {medium && renderSearchBar}
      </Grid>
    </Box>
  );
};

export default Header;
