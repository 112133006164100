/**
 * src/modules/admin/gql/brands.ts
 *
 * gql for authentication.
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/26/2023
 */
import { gql } from '@apollo/client';

export const GQL_QUERY_ALL_BRANDS = gql`
  query Brands($offset: Int!, $limit: Int!) {
    Brands(offset: $offset, limit: $limit) {
      total
      data {
        id
        mbId
        name
        description
        website
        street
        state
        city
        postalCode
        phoneNumber
        faxNumber
        tradeCurrency
      }
    }
  }
`;
