/**
 * src/modules/admin/components/brand-profile/BrandProfile.tsx
 *
 * Brand profile
 *
 * @author Bryan Henry <bhenry@materialbank.com>
 * @copyright Material Bank, 2023
 * @since 02/26/2023
 */

import React, { useContext } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as Contacts } from '../../../common/assets/24/contacts.svg';
import { ReactComponent as Delivery } from '../../../common/assets/24/delivery.svg';
import { ReactComponent as Info } from '../../../common/assets/24/info.svg';
import { ReactComponent as Link } from '../../../common/assets/24/link.svg';
import { ReactComponent as Pin } from '../../../common/assets/24/pin.svg';
import { ReactComponent as Project } from '../../../common/assets/24/project.svg';
import { BrandsCtx } from '../brands/Brands';

const BrandProfile = () => {
  const { rows, selectedBrand } = useContext(BrandsCtx);

  if (!rows) {
    return (
      <Box padding={4} textAlign='center'>
        <Typography variant='headline1'>No brands registered.</Typography>
      </Box>
    );
  }

  if (!selectedBrand) {
    return (
      <Box padding={4} textAlign='center'>
        <Typography variant='headline1'>Select a brand to know more.</Typography>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Box marginBottom={4}>
        <Typography variant='h5'>{selectedBrand.name}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Project style={{ marginRight: '8px' }} />
          MBID
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{selectedBrand.mbId}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Info style={{ marginRight: '8px' }} />
          Description
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{selectedBrand.description}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Link style={{ marginRight: '8px' }} />
          Website
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>{selectedBrand.website}</Grid>
        </Grid>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Pin style={{ marginRight: '8px' }} />
          Address
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{[selectedBrand.street, selectedBrand.city, selectedBrand.state, selectedBrand.postalCode].join(', ')}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Contacts style={{ marginRight: '8px' }} />
          Phone
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{selectedBrand.phoneNumber}</Typography>
      </Box>
      <Box marginBottom={4}>
        <Typography color='grey'>
          <Delivery style={{ marginRight: '8px' }} />
          Fax
        </Typography>
        <Divider sx={{ marginY: 1 }} />
        <Typography>{selectedBrand.faxNumber}</Typography>
      </Box>
    </Box>
  );
};

export default BrandProfile;
